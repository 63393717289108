import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { useConferences } from '../context/ConferenceContext'; // Import the useConferences hook
import 'react-calendar/dist/Calendar.css'; // Default calendar styles
import './ScheduleAndAgenda.css'; // Your custom styles

const ScheduleAndAgenda = () => {
  const [selectedConference, setSelectedConference] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null); // State for the selected session
  const [selectedSessions, setSelectedSessions] = useState([]); // State for available sessions
  const { conferences } = useConferences(); // Get the dynamic list of conferences
  const [searchQuery, setSearchQuery] = useState(''); // Search state

  // Updated sessions data for 2024
  const sessions = {
    1: {
      '2024-10-25': [ // Day 1 - AI & Machine Learning
        {
          time: '10:00 AM',
          title: 'AI in Healthcare',
          speaker: 'Dr. John Smith',
          bio: 'Expert in AI technologies applied to healthcare.',
          education: 'PhD in Computer Science from Stanford University, specialized in Artificial Intelligence.',
          expertise: 'AI and Machine Learning, Healthcare Technologies',
          description: 'Discussing the latest AI breakthroughs in the healthcare industry and how they are improving patient care and reducing costs.',
        },
        {
          time: '12:00 PM',
          title: 'Future of Data Science',
          speaker: 'Dr. Jane Doe',
          bio: 'Data scientist specializing in predictive analytics.',
          education: 'PhD in Data Science from MIT, specialized in Predictive Analytics and Big Data.',
          expertise: 'Data Science, Predictive Analytics, Big Data',
          description: 'Exploring future trends and challenges in data science, including real-world applications of predictive analytics.',
        },
        {
          time: '2:00 PM',
          title: 'Blockchain in Security',
          speaker: 'Dr. Alice Johnson',
          bio: 'Security specialist with expertise in blockchain technologies.',
          education: 'PhD in Information Security from Carnegie Mellon University.',
          expertise: 'Blockchain, Information Security',
          description: 'A deep dive into blockchain technologies and their role in modern security frameworks, particularly in finance and healthcare.',
        },
      ],
    },
    2: {
      '2024-10-26': [ // Day 2 - Data Science & Cybersecurity
        {
          time: '10:00 AM',
          title: 'Data Mining Techniques',
          speaker: 'Dr. Michael Clark',
          bio: 'Professor of Data Science and Machine Learning.',
          education: 'PhD in Data Science from UC Berkeley.',
          expertise: 'Data Mining, Machine Learning, Data Science',
          description: 'An overview of the latest data mining techniques used in research and industry, with a focus on scalability and accuracy.',
        },
        {
          time: '12:00 PM',
          title: 'Cybersecurity in 2024',
          speaker: 'Dr. Emily Lee',
          bio: 'Expert in Cybersecurity trends for the future.',
          education: 'PhD in Cybersecurity from MIT.',
          expertise: 'Cybersecurity, Risk Management',
          description: 'Discussing the upcoming challenges and technologies in cybersecurity for 2024.',
        },
        {
          time: '2:00 PM',
          title: 'Quantum Computing',
          speaker: 'Dr. Brian Adams',
          bio: 'Leading researcher in Quantum Computing applications.',
          education: 'PhD in Quantum Computing from Stanford University.',
          expertise: 'Quantum Computing, Theoretical Computer Science',
          description: 'An overview of quantum computing and its applications in various fields.',
        },
      ],
    },
    3: {
      '2024-10-27': [ // Day 3 - Cybersecurity Innovations
        {
          time: '10:00 AM',
          title: 'Cloud Computing in Research',
          speaker: 'Dr. Sarah Johnson',
          bio: 'Expert in cloud infrastructure and research methodologies.',
          education: 'PhD in Cloud Computing from UC Berkeley.',
          expertise: 'Cloud Computing, Data Analytics',
          description: 'The role of cloud computing in modern business and research, including its impact on scalability and data storage.',
        },
        {
          time: '12:00 PM',
          title: 'IoT and Cybersecurity',
          speaker: 'Dr. Robert Brown',
          bio: 'Cybersecurity and IoT specialist.',
          education: 'PhD in Information Systems from Princeton University.',
          expertise: 'IoT, Cybersecurity',
          description: 'Exploring the intersection of IoT technologies and cybersecurity, with real-world case studies of IoT security breaches.',
        },
      ],
    },
    4: {
      '2024-10-28': [ // Day 4 - Cloud Computing & IoT
        {
          time: '10:00 AM',
          title: 'IoT Device Security',
          speaker: 'Dr. Anne Lee',
          bio: 'Expert in IoT security protocols.',
          education: 'PhD in Cybersecurity from Harvard University.',
          expertise: 'IoT, Security',
          description: 'Understanding the security challenges of IoT devices and solutions to mitigate risks.',
        },
        {
          time: '12:00 PM',
          title: 'Scalable Cloud Solutions',
          speaker: 'Dr. John Doe',
          bio: 'Cloud architect specializing in scalable solutions.',
          education: 'PhD in Cloud Computing from Stanford University.',
          expertise: 'Cloud Computing, Scalability',
          description: 'Discussing best practices for designing scalable cloud solutions.',
        },
      ],
    },
    5: {
      '2024-10-29': [ // Day 5 - Quantum Computing
        {
          time: '10:00 AM',
          title: 'Quantum Algorithms',
          speaker: 'Dr. Emily Brown',
          bio: 'Expert in quantum algorithms and their applications.',
          education: 'PhD in Quantum Computing from MIT.',
          expertise: 'Quantum Computing, Algorithms',
          description: 'An introduction to quantum algorithms and their practical applications.',
        },
        {
          time: '12:00 PM',
          title: 'Applications of Quantum Computing',
          speaker: 'Dr. James Green',
          bio: 'Researcher in quantum computing applications across industries.',
          education: 'PhD in Quantum Computing from UC Berkeley.',
          expertise: 'Quantum Computing, Application Development',
          description: 'Exploring how quantum computing is transforming various industries.',
        },
      ],
    },
  };

  // Available dates for each conference
  const availableDates = {
    1: ['2024-10-25'], // Day 1
    2: ['2024-10-26'], // Day 2
    3: ['2024-10-27'], // Day 3
    4: ['2024-10-28'], // Day 4
    5: ['2024-10-29'], // Day 5
  };

  const handleConferenceChange = (e) => {
    setSelectedConference(e.target.value);
    setSelectedDate(null); // Reset selected date when the conference changes
    setSelectedSessions([]); // Clear sessions
    setSelectedSession(null); // Clear selected session
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const formattedDate = date.toISOString().split('T')[0]; // Format date as YYYY-MM-DD

    // Retrieve sessions for the selected conference and date
    if (sessions[selectedConference] && sessions[selectedConference][formattedDate]) {
      setSelectedSessions(sessions[selectedConference][formattedDate]);
    } else {
      setSelectedSessions([]);
      setSelectedSession(null); // Clear session if no sessions are available
    }
  };

  // Function to highlight available dates in the calendar
  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const formattedDate = date.toISOString().split('T')[0];
      // Check if the date is available for the selected conference
      if (availableDates[selectedConference]?.includes(formattedDate)) {
        return 'available-date'; // Apply custom class to available dates
      }
    }
    return null;
  };

  const handleViewDetails = (session) => {
    setSelectedSession(session);
  };

  const handleBack = () => {
    setSelectedSession(null);
  };

  // Search for sessions by speaker name or title
  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Filter sessions by the search query
  const filteredSessions = selectedSessions.filter((session) =>
    session.speaker.toLowerCase().includes(searchQuery) || session.title.toLowerCase().includes(searchQuery)
  );

  // Export sessions to CSV
  const exportToCSV = () => {
    if (selectedSessions.length === 0) {
      alert('No sessions to export.');
      return;
    }

    // CSV header
    const headers = ['Time', 'Session Title', 'Speaker', 'Expertise', 'Education', 'Description'];
    
    // CSV rows
    const rows = selectedSessions.map(session => [
      session.time,
      session.title,
      session.speaker,
      session.expertise,
      session.education,
      session.description,
    ]);

    // Combine headers and rows
    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(',')) // Join each row by commas
    ].join('\n');

    // Create a blob for the CSV data and trigger the download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', 'schedule.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main-content-container">
      <div className="main-content">
        <header>
          <h1>Conference Schedule & Agenda</h1>
          <p>Check out the full schedule and plan your sessions.</p>
        </header>

        {/* Conference Dropdown */}
        <section style={{ marginTop: '20px' }}>
          <label>Select Conference:</label>
          <select value={selectedConference} onChange={handleConferenceChange}>
            <option value="">-- Select Conference --</option>
            {conferences.map((conf) => (
              <option key={conf.id} value={conf.id}>
                {conf.name}
              </option>
            ))}
          </select>
        </section>

        {/* Calendar */}
        {selectedConference && (
          <section style={{ marginTop: '20px' }}>
            <label>Select Date:</label>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              tileClassName={tileClassName} // Highlight available dates
            />
          </section>
        )}

        {/* Search Sessions */}
        {selectedSessions.length > 0 && (
          <section style={{ marginTop: '20px' }}>
            <input
              type="text"
              placeholder="Search by speaker or session title"
              value={searchQuery}
              onChange={handleSearch}
              style={{ width: '100%', padding: '10px', marginBottom: '20px' }}
            />
          </section>
        )}

        {/* Display Sessions for the Selected Date */}
        {filteredSessions.length > 0 ? (
          <section style={{ marginTop: '20px' }}>
            <h2>Available Sessions</h2>
            <table className="schedule-table">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Session Title</th>
                  <th>Speaker</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredSessions.map((session, index) => (
                  <tr key={index}>
                    <td>{session.time}</td>
                    <td>{session.title}</td>
                    <td>{session.speaker}</td>
                    <td>
                      <button className="details-button" onClick={() => handleViewDetails(session)}>View Details</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Export Button */}
            <button className="export-button" onClick={exportToCSV}>Export to CSV</button>
          </section>
        ) : selectedConference && selectedDate ? (
          <p>No sessions available for the selected date.</p>
        ) : null}

        {/* Session Details View */}
        {selectedSession && (
          <div className="session-details" style={{ marginTop: '20px' }}>
            <h2>Speaker Profile: {selectedSession.speaker}</h2>
            <p><strong>Expertise:</strong> {selectedSession.expertise}</p>
            <p><strong>Education:</strong> {selectedSession.education}</p>
            <p><strong>Bio:</strong> {selectedSession.bio}</p>

            <h3>Session: {selectedSession.title}</h3>
            <p>{selectedSession.description}</p>

            <button className="back-button" onClick={handleBack}>Back to Schedule</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScheduleAndAgenda;

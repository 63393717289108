import React, { useState } from 'react';
import './Mentorship.css';

const Mentorship = () => {
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);

  const mentors = [
    { id: 1, name: 'Dr. John Smith', expertise: 'AI & Machine Learning', bio: 'PhD in Computer Science from MIT, 10 years of experience in AI.', timeSlots: ['10:00 AM', '2:00 PM', '3:00 PM'] },
    { id: 2, name: 'Dr. Jane Doe', expertise: 'Data Science & Analytics', bio: 'PhD in Data Science from Stanford, specializing in predictive analytics.', timeSlots: ['11:00 AM', '1:00 PM'] },
    { id: 3, name: 'Dr. Michael Clark', expertise: 'Cybersecurity Innovations', bio: 'PhD in Information Security from Carnegie Mellon University, 15 years of industry experience.', timeSlots: ['12:00 PM', '3:00 PM'] },
    { id: 4, name: 'Dr. Sarah Johnson', expertise: 'Cloud Computing & IoT', bio: 'PhD in Cloud Computing from University of Cambridge, expertise in IoT integrations.', timeSlots: ['9:00 AM', '1:00 PM'] },
    { id: 5, name: 'Dr. Emily Brown', expertise: 'Quantum Computing', bio: 'PhD in Quantum Computing from MIT, leading research in cryptography and computing power.', timeSlots: ['10:30 AM', '2:30 PM'] },
  ];

  const handleViewProfile = (mentor) => {
    setSelectedMentor(mentor);
    setAvailableTimeSlots(mentor.timeSlots); // Set available time slots based on selected mentor
    setSelectedTimeSlot(''); // Reset selected time slot
  };

  const handleBack = () => {
    setSelectedMentor(null);
    setAvailableTimeSlots([]); // Clear available time slots
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your submission logic here
    alert(`Request submitted for ${selectedMentor.name} at ${selectedTimeSlot}`);
  };

  return (
    <div className="mentorship-page">
      <div className="main-content">
        <header>
          <h1>Connect with a Mentor</h1>
          <p>Sign up for mentorship sessions with industry experts and enhance your career or academic journey.</p>
        </header>

        <section>
          <h2>Available Mentors</h2>
          <table className="schedule-table">
            <thead>
              <tr>
                <th>Mentor Name</th>
                <th>Area of Expertise</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mentors.map((mentor) => (
                <tr key={mentor.id}>
                  <td>{mentor.name}</td>
                  <td>{mentor.expertise}</td>
                  <td>
                    <button className="details-button" onClick={() => handleViewProfile(mentor)}>
                      View Profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        {/* Mentor Profile Section */}
        {selectedMentor && (
          <section className="mentor-profile">
            <h2>Mentor Profile: {selectedMentor.name}</h2>
            <p><strong>Expertise:</strong> {selectedMentor.expertise}</p>
            <p><strong>Bio:</strong> {selectedMentor.bio}</p>

            <h3>Select a Time Slot</h3>
            <select value={selectedTimeSlot} onChange={(e) => setSelectedTimeSlot(e.target.value)} defaultValue="">
              <option value="" disabled>Select a time slot...</option>
              {availableTimeSlots.map((timeSlot, index) => (
                <option key={index} value={timeSlot}>{timeSlot}</option>
              ))}
            </select>

            <button className="back-button" onClick={handleBack}>Back to Mentors</button>
          </section>
        )}

        {/* Register for Mentorship */}
        {selectedMentor && selectedTimeSlot && (
          <section>
            <h2>Register for Mentorship</h2>
            <form className="mentorship-form" onSubmit={handleSubmit}>
              <label>
                Full Name:
                <input type="text" required />
              </label>

              <label>
                Email:
                <input type="email" required />
              </label>

              <button type="submit">Submit</button>
            </form>
          </section>
        )}

        <div className="faq8-box">
          <h2>Frequently Asked Questions</h2>
          <ul>
            <li><strong>Q:</strong> How do I select a mentor?<br /><strong>A:</strong> Use the dropdown to select a mentor based on your area of interest.</li>
            <li><strong>Q:</strong> How do I schedule a session?<br /><strong>A:</strong> After submitting your request, you will receive a scheduling link via email.</li>
            <li><strong>Q:</strong> Can I change my mentor?<br /><strong>A:</strong> Yes, you can change your mentor by submitting a new request.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Mentorship;

import React, { useState } from 'react';
import { useConferences } from '../context/ConferenceContext'; // Use the context

const AdminDashboard = () => {
  const [conferenceName, setConferenceName] = useState('');
  const { conferences, addConference } = useConferences(); // Get the conferences and add function from context

  const handleAddConference = (e) => {
    e.preventDefault();
    if (conferenceName) {
      addConference(conferenceName); // Add conference to the list
      setConferenceName(''); // Clear input
    }
  };

  return (
    <div className="main-content">
      <h2>Admin Dashboard</h2>
      <form onSubmit={handleAddConference}>
        <input
          type="text"
          placeholder="Add Conference Name"
          value={conferenceName}
          onChange={(e) => setConferenceName(e.target.value)}
          required
        />
        <button type="submit">Add Conference</button>
      </form>

      <div className="section">
        <h3>Conference List</h3>
        <ul>
          {conferences.map((conference) => (
            <li key={conference.id}>{conference.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminDashboard;

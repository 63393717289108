import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './JoinLiveSession.css';
import { useConferences } from '../context/ConferenceContext'; // Import the useConferences hook

const JoinLiveSession = () => {
  const { id, conferenceType } = useParams(); // Extract both id and conferenceType from URL params
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const { conferences } = useConferences(); // Get the dynamic list of conferences

  // Conference data for live sessions
  const conferenceData = {
    1: [
      { id: 1, time: '10:00 AM', title: 'AI in Healthcare', speaker: 'Dr. John Smith', description: 'An in-depth session on AI applications in healthcare.' },
      { id: 2, time: '12:00 PM', title: 'AI & ML Advances', speaker: 'Dr. Alice Brown', description: 'Latest AI and ML techniques transforming industries.' },
      { id: 3, time: '2:00 PM', title: 'Deep Learning Trends', speaker: 'Dr. Rahul Kumar', description: 'Explore the future of deep learning and neural networks.' },
    ],
    2: [
      { id: 1, time: '10:00 AM', title: 'Data Science Trends', speaker: 'Dr. Jane Doe', description: 'Latest trends in data science and their applications.' },
      { id: 2, time: '1:00 PM', title: 'Data Mining Techniques', speaker: 'Dr. John Parker', description: 'Best practices in data mining for business.' },
      { id: 3, time: '3:00 PM', title: 'Big Data in Business', speaker: 'Dr. Amanda Cooper', description: 'How big data is revolutionizing business analytics.' },
    ],
    3: [
      { id: 1, time: '11:00 AM', title: 'Cybersecurity Best Practices', speaker: 'Dr. Michael Green', description: 'Best practices for securing systems in 2024.' },
      { id: 2, time: '2:00 PM', title: 'Cloud Security', speaker: 'Dr. Sarah Johnson', description: 'Security challenges in cloud computing.' },
    ],
    4: [
      { id: 1, time: '10:00 AM', title: 'Advances in Cloud Computing', speaker: 'Dr. Robert White', description: 'Latest cloud innovations.' },
      { id: 2, time: '1:00 PM', title: 'IoT & Cloud', speaker: 'Dr. Emily Clark', description: 'IoT’s integration with cloud computing.' },
    ],
   5: [
      { id: 1, time: '11:00 AM', title: 'Quantum Computing in Research', speaker: 'Dr. Emily Brown', description: 'How quantum computing is advancing research.' },
      { id: 2, time: '3:00 PM', title: 'Quantum Cryptography', speaker: 'Dr. Alex Wright', description: 'Introduction to quantum cryptography.' },
    ],
  };

  const sessions = conferenceData[conferenceType] || [];
  const session = sessions.find((s) => s.id === parseInt(id));

  // Handle case when session is not found
  if (!session) {
    return <p>Session not found</p>;
  }

  const handleSendMessage = () => {
    if (message) {
      setMessages([...messages, message]);
      setMessage('');
    }
  };

  return (
    <div className="main-content">
      <h1>{session.title}</h1>
      <p><strong>Speaker:</strong> {session.speaker}</p>
      <p><strong>Time:</strong> {session.time}</p>
      <p><strong>Description:</strong> {session.description}</p>

      {/* Video Stream Placeholder */}
      <div className="video-box">
        <p>Live stream video will appear here.</p>
      </div>

      {/* Chat Section */}
      <div className="chat-section">
        <h3>Live Chat</h3>
        <div className="chat-box">
          {messages.length === 0 ? (
            <p>No messages yet. Be the first to send a message!</p>
          ) : (
            messages.map((msg, index) => <p key={index}><strong>You:</strong> {msg}</p>)
          )}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default JoinLiveSession;
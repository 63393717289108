import React, { createContext, useState, useContext } from 'react';

// Create the context
const ConferenceContext = createContext();

// Custom hook to use the conference context
export const useConferences = () => {
  return useContext(ConferenceContext);
};

// Conference provider component to wrap the app
export const ConferenceProvider = ({ children }) => {
  const [conferences, setConferences] = useState([
    { id: 1, name: 'AI & Machine Learning' },
    { id: 2, name: 'Data Science & Analytics' },
    { id: 3, name: 'Cybersecurity Innovations' },
    { id: 4, name: 'Cloud Computing & IoT' },
    { id: 5, name: 'Quantum Computing' },
  ]);

  const addConference = (newConference) => {
    const newId = conferences.length + 1;
    const updatedConferences = [...conferences, { id: newId, name: newConference }];
    setConferences(updatedConferences);
  };

  return (
    <ConferenceContext.Provider value={{ conferences, addConference }}>
      {children}
    </ConferenceContext.Provider>
  );
};

import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <div className="main-content">
      <header>
        <h1>Contact Us</h1>
        <p>Have questions or need assistance? Reach out to us below.</p>
      </header>

      <section>
        <h2>Contact Form</h2>
        <form>
          <label>
            Name:
            <input type="text" required />
          </label>

          <label>
            Email:
            <input type="email" required />
          </label>

          <label>
            Subject:
            <select required>
              <option value="general">General Inquiry</option>
              <option value="support">Technical Support</option>
              <option value="feedback">Feedback</option>
            </select>
          </label>

          <label>
            Message:
            <textarea rows="4" required></textarea>
          </label>

          <button type="submit">Submit</button>
        </form>
      </section>

      <section className="contact-info-container">
        {/* Address Section */}
        <div className="address-section">
          <h2>Our Address</h2>
          <p>University of Texas at Arlington</p>
          <p>701 S Nedderman Dr, Arlington, TX 76019, USA</p>
        </div>

        {/* Google Maps Section with a Pin */}
        <div className="map-section">
          <h2>Our Location</h2>
          <iframe
            title="UTA Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3357.9643645024417!2d-97.11856408545452!3d32.73161098099383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7cc82b9d5b8f%3A0x19d4b226b0fe2149!2sUniversity%20of%20Texas%20at%20Arlington!5e0!3m2!1sen!2sus!4v1697191305083!5m2!1sen!2sus&z=15&marker=University+of+Texas+at+Arlington"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>

      <section>
        <h2>Follow Us</h2>
        <p>Stay updated via our social media channels:</p>
        <ul>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>LinkedIn</li>
        </ul>
      </section>
    </div>
  );
};

export default ContactUs;

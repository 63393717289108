import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './VirtualConference.css';
import { useConferences } from '../context/ConferenceContext'; // Import the useConferences hook


const VirtualConference = () => {
  const [selectedConference, setSelectedConference] = useState(1); 
  const { conferences } = useConferences(); // Get the dynamic list of conferences

  const conferenceData = {
    1: {
      liveSessions: [
        { id: 1, time: '10:00 AM', title: 'AI in Healthcare', speaker: 'Dr. John Smith' },
        { id: 2, time: '12:00 PM', title: 'AI & ML Advances', speaker: 'Dr. Alice Brown' },
        { id: 3, time: '2:00 PM', title: 'Deep Learning Trends', speaker: 'Dr. Rahul Kumar' },
      ],
      recordedSessions: [
        { id: 1, title: 'AI & Machine Learning', watched: true, link: 'https://youtube.com' },
        { id: 2, title: 'AI Ethics and Society', watched: true, link: 'https://youtube.com' },
      ],
    },
    2: {
      liveSessions: [
        { id: 1, time: '10:00 AM', title: 'Data Science Trends', speaker: 'Dr. Jane Doe' },
        { id: 2, time: '1:00 PM', title: 'Data Mining Techniques', speaker: 'Dr. John Parker' },
        { id: 3, time: '3:00 PM', title: 'Big Data in Business', speaker: 'Dr. Amanda Cooper' },
      ],
      recordedSessions: [
        { id: 1, title: 'Data Science 2024', watched: true, link: 'https://youtube.com' },
        { id: 2, title: 'Advanced Data Analytics', watched: false, link: 'https://youtube.com' },
      ],
    },
    3: {
      liveSessions: [
        { id: 1, time: '11:00 AM', title: 'Cybersecurity Best Practices', speaker: 'Dr. Michael Green' },
        { id: 2, time: '2:00 PM', title: 'Cloud Security', speaker: 'Dr. Sarah Johnson' },
      ],
      recordedSessions: [
        { id: 1, title: 'Cybersecurity 2024', watched: true, link: 'https://youtube.com' },
        { id: 2, title: 'Network Security Fundamentals', watched: false, link: 'https://youtube.com' },
      ],
    },
    4: {
      liveSessions: [
        { id: 1, time: '10:00 AM', title: 'Advances in Cloud Computing', speaker: 'Dr. Robert White' },
        { id: 2, time: '1:00 PM', title: 'IoT & Cloud', speaker: 'Dr. Emily Clark' },
      ],
      recordedSessions: [
        { id: 1, title: 'Cloud Computing Basics', watched: true, link: 'https://youtube.com' },
        { id: 2, title: 'IoT in Cloud Computing', watched: false, link: 'https://youtube.com' },
      ],
    },
    5: {
      liveSessions: [
        { id: 1, time: '11:00 AM', title: 'Quantum Computing in Research', speaker: 'Dr. Emily Brown' },
        { id: 2, time: '3:00 PM', title: 'Quantum Cryptography', speaker: 'Dr. Alex Wright' },
      ],
      recordedSessions: [
        { id: 1, title: 'Quantum Computing Overview', watched: true, link: 'https://youtube.com' },
        { id: 2, title: 'Quantum Algorithms', watched: false, link: 'https://youtube.com' },
      ],
    },
  };

  const selectedLiveSessions = conferenceData[selectedConference].liveSessions;
  const selectedRecordedSessions = conferenceData[selectedConference].recordedSessions;

  return (
    <div>
      <div className="main-content">
        <header>
          <h1>Join the Virtual Conference</h1>
          <p>Select a conference to view live and recorded sessions!</p>
        </header>

        <section>
          <h2>Select Conference</h2>
          <select
            value={selectedConference}
            onChange={(e) => setSelectedConference(e.target.value)}
            className="conference-select"
          >
            {conferences.map((conf) => (
              <option key={conf.id} value={conf.id}>
                {conf.name}
              </option>
            ))}
          </select>
        </section>

        {/* Live Sessions Table */}
        <section>
          <h2>Live Sessions</h2>
          <table className="conference-table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Session Title</th>
                <th>Speaker</th>
                <th>Join</th>
              </tr>
            </thead>
            <tbody>
              {selectedLiveSessions.map((session) => (
                <tr key={session.id}>
                  <td>{session.time}</td>
                  <td>{session.title}</td>
                  <td>{session.speaker}</td>
                  <td>
                    <Link to={`/join-live-session/${selectedConference}/${session.id}`}>
                      <button className='details-button'>Join Now</button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section>
          <h2>Recorded Sessions</h2>
          <table className="conference-table">
            <thead>
              <tr>
                <th>Session Title</th>
                <th>Status</th>
                <th>Watch</th>
              </tr>
            </thead>
            <tbody>
              {selectedRecordedSessions.map((session) => (
                <tr key={session.id}>
                  <td>{session.title}</td>
                  <td>{session.watched ? 'Watched' : 'Not Watched'}</td>
                  <td>
                    <a href={session.link} target="_blank" rel="noopener noreferrer">
                      Watch Now
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

export default VirtualConference;
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './CallForPapers.css';

const submissions = {
  'AI and Machine Learning': [
    { author: 'John Doe', deadline: 'Oct 10, 2024' },
  ],
  'Data Science and Analytics': [
    { author: 'Michael Green', deadline: 'Oct 13, 2024' }
  ],
  'Cybersecurity Innovations': [
    { author: 'Sarah Johnson', deadline: 'Oct 14, 2024' }
  ],
  'Cloud Computing and IoT': [
    { author: 'Emily Brown', deadline: 'Oct 15, 2024' }
  ],
  'Quantum Computing': [
    { author: 'James Black', deadline: 'Oct 16, 2024' }
  ]
};

const CallForPapers = () => {
  return (
    <div className="cp-page-layout">
      <div className="cp-content">
        <header>
          <h1>Call for Papers</h1>
          <p>Submit your research and join the academic conversation!</p>
        </header>

        <section>
          <h2>Submission Guidelines</h2>
          <ul>
            <li>Papers must be submitted in PDF format.</li>
            <li>Ensure your paper follows the conference-specific template.</li>
            <li>Papers should not exceed 10 pages, including references.</li>
            <li>You will receive a confirmation email upon successful submission.</li>
            <li>Authors will be notified via email about the review results.</li>
          </ul>
        </section>

        <section>
          <h2>Important Dates</h2>
          <ul>
            <li>Submission Deadline: Oct 15, 2024</li>
            <li>Notification of Acceptance: Nov 10, 2024</li>
            <li>Final Paper Due: Nov 30, 2024</li>
          </ul>
        </section>

        <section>
          <h2>Submission Overview</h2>
          <table className="submission-table">
            <thead>
              <tr>
                <th>Conference Track</th>
                <th>Author(s)</th>
                <th>Submission Deadline</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(submissions).map(([track, papers], index) => (
                papers.map((paper, idx) => (
                  <tr key={`${index}-${idx}`}>
                    <td>{track}</td>
                    <td>{paper.author}</td>
                    <td>{paper.deadline}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </table>
        </section>

        <section>
          <br /><Link to="/submissions"><button className="details-button">Submit Your Paper</button></Link>
        </section>
      </div>
      
      <div className="faq2-box">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <li><strong>Q:</strong> What format should my paper be in?<br/><strong>A:</strong> Please submit in PDF format.</li>
          <li><strong>Q:</strong> Is there a template for submission?<br/><strong>A:</strong> Yes, please use the provided template.</li>
          <li><strong>Q:</strong> How many pages can my paper be?<br/><strong>A:</strong> The maximum length is 10 pages, including references.</li>
          <li><strong>Q:</strong> When will I know if my paper is accepted?<br/><strong>A:</strong> Notifications will be sent out by Nov 10, 2024.</li>
          <li><strong>Q:</strong> Can I submit multiple papers?<br/><strong>A:</strong> Yes, you can submit multiple papers.</li>
        </ul>
      </div>     
    </div>
  );
};

export default CallForPapers;

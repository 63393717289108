import React, { useState } from 'react';
import './CareerDevelopment.css';

const CareerDevelopment = () => {
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedConference, setSelectedConference] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search input

  const resources = [
    {
      title: 'Career Workshops',
      description: 'Hands-on workshops to help you build your professional skills, including resume building, interview techniques, and career planning.',
      link: 'https://www.exampleworkshop.com',
    },
    {
      title: 'Webinars on Skill Development',
      description: 'Join our live and recorded webinars focusing on essential skills like leadership, communication, and technical expertise.',
      link: 'https://www.examplewebinar.com',
    },
  ];

  const jobBoard = {
    'AI & Machine Learning': [
      {
        title: 'AI Research Scientist',
        company: 'Tech Innovators Inc.',
        location: 'San Francisco, CA',
        description: 'Seeking an AI Research Scientist to work on cutting-edge AI technologies.',
        applyLink: 'https://www.examplejobboard.com/ai-research-scientist',
      },
      {
        title: 'Machine Learning Engineer',
        company: 'AI Solutions',
        location: 'Boston, MA',
        description: 'Develop and implement machine learning models for healthcare applications.',
        applyLink: 'https://www.examplejobboard.com/ml-engineer',
      },
    ],
    'Data Science & Analytics': [
      {
        title: 'Data Scientist',
        company: 'Data Insights Corp.',
        location: 'New York, NY',
        description: 'Looking for a Data Scientist with expertise in machine learning and big data.',
        applyLink: 'https://www.examplejobboard.com/data-scientist',
      },
      {
        title: 'Big Data Engineer',
        company: 'Analytics Wizards',
        location: 'Remote',
        description: 'Work with big data technologies to build scalable data pipelines.',
        applyLink: 'https://www.examplejobboard.com/big-data-engineer',
      },
    ],
    'Cybersecurity Innovations': [
      {
        title: 'Cybersecurity Analyst',
        company: 'SecureNet Solutions',
        location: 'Remote',
        description: 'Hiring a Cybersecurity Analyst to help improve security protocols and incident response.',
        applyLink: 'https://www.examplejobboard.com/cybersecurity-analyst',
      },
      {
        title: 'Cloud Security Engineer',
        company: 'CloudShield Inc.',
        location: 'Austin, TX',
        description: 'Design and implement security measures for cloud-based applications.',
        applyLink: 'https://www.examplejobboard.com/cloud-security-engineer',
      },
    ],
    'Cloud Computing & IoT': [
      {
        title: 'Cloud Architect',
        company: 'Cloud Innovators',
        location: 'Seattle, WA',
        description: 'Seeking a Cloud Architect to design and manage cloud infrastructures.',
        applyLink: 'https://www.examplejobboard.com/cloud-architect',
      },
      {
        title: 'IoT Cloud Engineer',
        company: 'IoT Solutions',
        location: 'Chicago, IL',
        description: 'Develop IoT applications with integration to cloud platforms.',
        applyLink: 'https://www.examplejobboard.com/iot-cloud-engineer',
      },
    ],
    'Quantum Computing': [
      {
        title: 'Quantum Computing Researcher',
        company: 'Quantum Innovations',
        location: 'Cambridge, MA',
        description: 'Research and develop quantum algorithms for cryptography.',
        applyLink: 'https://www.examplejobboard.com/quantum-researcher',
      },
      {
        title: 'Quantum Software Developer',
        company: 'Qubit Labs',
        location: 'Remote',
        description: 'Develop quantum software applications for next-gen computing solutions.',
        applyLink: 'https://www.examplejobboard.com/quantum-software-developer',
      },
    ],
  };

  const handleViewDetails = (resource) => {
    setSelectedResource(resource);
  };

  const handleViewJobDetails = (job) => {
    setSelectedJob(job);
  };

  const handleBack = () => {
    setSelectedResource(null);
    setSelectedJob(null);
    setSelectedConference(null);
  };

  // Filter jobs based on the search term
  const filteredJobs = selectedConference
    ? jobBoard[selectedConference].filter(
        (job) =>
          job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.location.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className="cd-page-layout">
      <div className="main-content">
        <header>
          <h1>Career Development</h1>
          <p>Explore our resources for career advancement, including articles, workshops, webinars, and a job board featuring academic and research opportunities.</p>
        </header>

        {!selectedResource && !selectedJob ? (
          <>
            {/* Job Board Section */}
            <section>
              <h2>Job Board</h2>
              {!selectedConference ? (
                <div className="conference-list">
                  <h3>Select a Conference to View Available Jobs:</h3>
                  <ul className="conference-links">
                    {Object.keys(jobBoard).map((conference, idx) => (
                      <li key={idx}>
                        <button className="conference-button" onClick={() => setSelectedConference(conference)}>
                          {conference}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    placeholder="Search jobs by title, company, or location..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="job-search-input large-input" // Made search bar larger
                  />
                  <h3>Jobs for {selectedConference}</h3>
                  {filteredJobs.length > 0 ? (
                    <table className="schedule-table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Company</th>
                          <th>Location</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredJobs.map((job, idx) => (
                          <tr key={idx}>
                            <td>{job.title}</td>
                            <td>{job.company}</td>
                            <td>{job.location}</td>
                            <td>
                              <button className="details-button" onClick={() => handleViewJobDetails(job)}>
                                View Details
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No jobs found matching your search criteria.</p>
                  )}
                  <button className="back-button" onClick={handleBack}>
                    Back to Conference List
                  </button>
                </>
              )}
            </section>

            <hr className="section-divider" /> {/* Divider between Job Board and Resources */}

            <section>
              <h2>Available Resources</h2>
              <table className="schedule-table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {resources.map((resource, index) => (
                    <tr key={index}>
                      <td>{resource.title}</td>
                      <td>{resource.description}</td>
                      <td>
                        <button className="details-button" onClick={() => handleViewDetails(resource)}>
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </>
        ) : selectedResource ? (
          <section className="resource-details">
            <h2>{selectedResource.title}</h2>
            <p>{selectedResource.description}</p>
            <a href={selectedResource.link} className="external-link" target="_blank" rel="noopener noreferrer">
              Visit {selectedResource.title}
            </a>
            <button className="back-button" onClick={handleBack}>Back to Resources</button>
          </section>
        ) : (
          <section className="job-details">
            <h2>{selectedJob.title}</h2>
            <p><strong>Company:</strong> {selectedJob.company}</p>
            <p><strong>Location:</strong> {selectedJob.location}</p>
            <p>{selectedJob.description}</p>
            <a href={selectedJob.applyLink} className="external-link" target="_blank" rel="noopener noreferrer">
              Apply for {selectedJob.title}
            </a>
            <button className="back-button" onClick={handleBack}>Back to Job Board</button>
          </section>
        )}
      </div>

      <div className="faq7-box">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <li><strong>Q:</strong> Are the workshops free?<br /><strong>A:</strong> Yes, all workshops are free for registered conference attendees.</li>
          <li><strong>Q:</strong> How do I register for webinars?<br /><strong>A:</strong> You can register for webinars through the provided links.</li>
          <li><strong>Q:</strong> How often is the job board updated?<br /><strong>A:</strong> The job board is updated weekly with new opportunities.</li>
        </ul>
      </div>
    </div>
  );
};

export default CareerDevelopment;
import React, { useState } from 'react';
import { useConferences } from '../context/ConferenceContext'; // Import the useConferences hook
import './SubmissionsPortal.css';

const SubmissionsPortal = () => {
  const [formData, setFormData] = useState({
    title: '',
    author: '',
    email: '',
    file: null,
    conference: '',
  });

  const { conferences } = useConferences(); // Get the list of conferences from the context

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Paper:", formData);
    alert('Your paper has been submitted successfully! You will receive a confirmation email shortly.');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
  };

  return (
    <div className="main-content">
      <header>
        <h1>Submissions Portal</h1>
        <p>Submit your paper to the relevant conference track.</p>
      </header>

      <form onSubmit={handleSubmit}>
        <label>
          Select Conference:
          <select
            name="conference"
            value={formData.conference}
            onChange={handleChange}
            required
          >
            <option value="">--Select Conference--</option>
            {conferences.map(conference => (
              <option key={conference.id} value={conference.name}>
                {conference.name}
              </option>
            ))}
          </select>
        </label>

        <label>
          Paper Title:
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Author Name:
          <input
            type="text"
            name="author"
            value={formData.author}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Upload Paper:
          <input
            type="file"
            name="file"
            onChange={handleFileChange}
            required
          />
        </label>

        <button type="submit">Submit Paper</button>
      </form>

      <section className="faq4-box">
        <h2>Submission Guidelines</h2>
        <ul>
          <li>Papers must be submitted in PDF format.</li>
          <li>Ensure your paper follows the conference-specific template.</li>
          <li>Papers should not exceed 10 pages, including references.</li>
          <li>You will receive a confirmation email upon successful submission.</li>
          <li>Authors will be notified via email about the review results.</li>
        </ul>
      </section>

      <section className="faq5-box">
        <h2>FAQs</h2>
        <ul>
          <li><strong>Q: Can I submit multiple papers?</strong></li>
          <p>A: Yes, you can submit multiple papers, but each must be in a separate submission.</p>

          <li><strong>Q: What formats are accepted for submission?</strong></li>
          <p>A: Only PDF format is accepted.</p>

          <li><strong>Q: How will I know if my paper was accepted?</strong></li>
          <p>A: You will receive a notification via email after the review process is complete.</p>
        </ul>
      </section>
    </div>
  );
};

export default SubmissionsPortal;

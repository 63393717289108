import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import CallForPapers from './pages/CallForPapers';
import AdminDashboard from './pages/AdminDashboard';
import SubmissionsPortal from './pages/SubmissionsPortal';
import PeerReview from './pages/PeerReview';
import ScheduleAndAgenda from './pages/ScheduleAndAgenda';
import RegistrationAndTicketing from './pages/RegistrationAndTicketing';
import VirtualConference from './pages/VirtualConference';
import Mentorship from './pages/Mentorship';
import CareerDevelopment from './pages/CareerDevelopment';
import ContactUs from './pages/ContactUs';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Login from './pages/Login';
import Register from './pages/Register';
import JoinLiveSession from './pages/JoinLiveSession';
import { ConferenceProvider } from './context/ConferenceContext'; // Import the context provider

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check login status from local storage on component mount
  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(loggedInStatus === 'true');
  }, []);

  return (
    <ConferenceProvider>
      <Router>
        <div>
          <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} /> {/* Keep this route */}
            <Route path="/call-for-papers" element={<CallForPapers />} />
            <Route path="/submissions" element={<SubmissionsPortal />} />
            <Route path="/peer-review" element={<PeerReview />} />
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/schedule-and-agenda" element={<ScheduleAndAgenda />} />
            <Route path="/registration-and-ticketing" element={<RegistrationAndTicketing />} />
            <Route path="/virtual-conference" element={<VirtualConference />} />
            <Route path="/career-development" element={<CareerDevelopment />} />
            <Route path="/mentorship" element={<Mentorship />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/register" element={<Register />} />
            <Route path="/join-live-session/:conferenceType/:id" element={<JoinLiveSession />} />
          </Routes>
          <Footer /> 
        </div>
      </Router>
    </ConferenceProvider>
  );
};

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = ({ setIsLoggedIn }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    expertise: '', // New state for expertise
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Conference options for expertise selection
  const conferenceOptions = [
    'AI & Machine Learning',
    'Data Science & Analytics',
    'Cybersecurity Innovations',
    'Cloud Computing & IoT',
    'Quantum Computing',
  ];

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    console.log('User registered:', formData);

    setIsLoggedIn(true);
    navigate('/home');
  };

  return (
    <div className="register-page">
      <form onSubmit={handleSubmit}>
        <h2>Register</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />

        <label>Confirm Password</label>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
        />

        <label>Expertise</label>
        <select
          name="expertise"
          value={formData.expertise}
          onChange={handleChange}
          required
        >
          <option value="">-- Select Expertise --</option>
          {conferenceOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;

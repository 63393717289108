import React, { useState } from 'react';
import './RegistrationAndTicketing.css';
const RegistrationAndTicketing = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    ticketType: 'general',
    paymentStatus: '',
  });
  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Simulate sending data to a backend
    setIsSubmitted(true);
    console.log("Registration Data:", formData);
  };

  const handlePayment = () => {
    // Simulate payment processing
    setTimeout(() => {
      setIsPaymentConfirmed(true);
      setFormData({ ...formData, paymentStatus: 'Paid' });
    }, 2000); // Simulating a 2 second delay for payment confirmation
  };

  return (
    <div>
      <div className="main-content">
        <header>
          <h1>Conference Registration & Ticketing</h1>
          <p>Register now and secure your spot for Confio 2024!</p>
        </header>

        {!isSubmitted ? (
          <form onSubmit={handleFormSubmit}>
            <label>
              Full Name:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </label>

            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </label>

            <label>
              Ticket Type:
              <select
                name="ticketType"
                value={formData.ticketType}
                onChange={handleInputChange}
                required
              >
                <option value="general">General Admission - $99</option>
                <option value="vip">VIP Access - $199</option>
                <option value="student">Student Admission - $49</option>
              </select>
            </label>

            <button type="submit" className="register-button">
              Register
            </button>
          </form>
        ) : (
          <div className="confirmation-box">
            <h2>Thank you, {formData.name}!</h2>
            <p>
              You have successfully registered with the {formData.ticketType === 'general' ? 'General Admission' : formData.ticketType === 'vip' ? 'VIP Access' : 'Student Admission'} ticket.
            </p>
            <p>A confirmation email has been sent to {formData.email}.</p>
            {!isPaymentConfirmed ? (
              <div className="payment-section">
                <h3>Proceed to Payment</h3>
                <button className="payment-button" onClick={handlePayment}>
                  Pay Now
                </button>
              </div>
            ) : (
              <div className="payment-confirmation">
                <h3>Payment Confirmed</h3>
                <p>Your payment of ${formData.ticketType === 'general' ? '99' : formData.ticketType === 'vip' ? '199' : '49'} has been successfully processed.</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationAndTicketing;

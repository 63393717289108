import React, { useState } from 'react';
import './PeerReview.css';
import { useConferences } from '../context/ConferenceContext'; // Import the useConferences hook


const PeerReview = () => {
  const [selectedConference, setSelectedConference] = useState('');
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [reviewFeedback, setReviewFeedback] = useState('');
  const [rating, setRating] = useState(0);

  const { conferences } = useConferences(); // Get the list of conferences from the context

  const papersByConference = {
    1: [
      { id: 1, title: 'AI in Healthcare', author: 'John Doe', status: 'Pending Review', expertise: 'AI and Machine Learning' },
      { id: 2, title: 'Deep Learning in Medical Imaging', author: 'Jane Doe', status: 'Pending Review', expertise: 'AI and Healthcare' },
      { id: 3, title: 'Neural Networks in Diagnostics', author: 'Paul Brown', status: 'Pending Review', expertise: 'AI and Diagnostics' },
    ],
    2: [
      { id: 4, title: 'Data Mining Techniques', author: 'Michael Clark', status: 'Pending Review', expertise: 'Data Science and Analytics' },
      { id: 5, title: 'Big Data and Cloud Computing', author: 'Emily Lee', status: 'Pending Review', expertise: 'Data Science and Big Data' },
      { id: 6, title: 'Predictive Analytics in Healthcare', author: 'Sophia Green', status: 'Pending Review', expertise: 'Predictive Analytics' },
    ],
    3: [
      { id: 7, title: 'Cybersecurity Best Practices', author: 'Sarah Johnson', status: 'Pending Review', expertise: 'Cybersecurity Innovations' },
      { id: 8, title: 'Blockchain in Security', author: 'Emily Brown', status: 'Pending Review', expertise: 'Blockchain and Cybersecurity' },
      { id: 9, title: 'Zero Trust Architecture', author: 'Henry Ford', status: 'Pending Review', expertise: 'Cybersecurity and Zero Trust' },
    ],
    4: [
      { id: 10, title: 'Advances in Cloud Computing', author: 'Sam Wilson', status: 'Pending Review', expertise: 'Cloud Computing and IoT' },
      { id: 11, title: 'IoT Security Challenges', author: 'David Lee', status: 'Pending Review', expertise: 'Cloud Computing and IoT' },
      { id: 12, title: 'Edge Computing for IoT Devices', author: 'Anna White', status: 'Pending Review', expertise: 'IoT and Edge Computing' },
    ],
    5: [
      { id: 13, title: 'Quantum Computing in Research', author: 'Alice Green', status: 'Pending Review', expertise: 'Quantum Computing' },
      { id: 14, title: 'Quantum Encryption Techniques', author: 'James Brown', status: 'Pending Review', expertise: 'Quantum Encryption' },
      { id: 15, title: 'Quantum Algorithms for AI', author: 'Laura Black', status: 'Pending Review', expertise: 'Quantum AI' },
    ],
  };

  const handleConferenceChange = (e) => {
    setSelectedConference(e.target.value);
    setSelectedPaper(null); 
  };

  const handleView = (paper) => {
    setSelectedPaper(paper);
  };

  const handleReviewSubmit = () => {
    console.log(`Paper Reviewed: ${selectedPaper.title}`);
    console.log(`Rating: ${rating}/5`);
    console.log(`Feedback: ${reviewFeedback}`);
    setSelectedPaper(null);
    setReviewFeedback('');
    setRating(0);
  };

  return (
    <div className="pr-page-layout">
      <div className="pr-content">
        <h1>Peer Review Dashboard</h1>

        <div className="conference-dropdown">
          <label htmlFor="conferenceSelect">Select Conference:</label>
          <select
            id="conferenceSelect"
            value={selectedConference}
            onChange={handleConferenceChange}
          >
            <option value="">-- Select Conference --</option>
            {conferences.map((conf) => (
              <option key={conf.id} value={conf.id}>
                {conf.name}
              </option>
            ))}
          </select>
        </div>

        {selectedConference && !selectedPaper ? (
          <section>
            <h2>Assigned Papers</h2>
            <table className="schedule-table">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Status</th>
                  <th>Expertise</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {papersByConference[selectedConference]?.map((paper) => (
                  <tr key={paper.id}>
                    <td>{paper.title}</td>
                    <td>{paper.author}</td>
                    <td>{paper.status}</td>
                    <td>{paper.expertise}</td>
                    <td>
                      <button className="details-button" onClick={() => handleView(paper)}>View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        ) : selectedPaper ? (
          <div>
            <h2>Review Paper: {selectedPaper.title}</h2>
            <p><strong>Author:</strong> {selectedPaper.author}</p>
            <p><strong>Expertise Required:</strong> {selectedPaper.expertise}</p>

            <div className="rating-system">
              <label>Rate the submission:</label>
              <select value={rating} onChange={(e) => setRating(e.target.value)}>
                <option value="0">Select Rating</option>
                {[1, 2, 3, 4, 5].map(score => (
                  <option key={score} value={score}>{score}</option>
                ))}
              </select>
            </div>

            <div className="feedback-section">
              <label>Write your feedback:</label>
              <textarea
                rows="5"
                placeholder="Provide detailed feedback for the paper"
                value={reviewFeedback}
                onChange={(e) => setReviewFeedback(e.target.value)}
                required
              ></textarea>
            </div>

            <button className="details-button" onClick={handleReviewSubmit}>Submit Review</button>
            <button className="back-button" onClick={() => setSelectedPaper(null)}>Back to List</button>
          </div>
        ) : null}
      </div>

      <div className="faq6-box">
        <h2>Frequently Asked Questions</h2>
        <ul>
          <li><strong>Q:</strong> How do I rate a submission?<br /><strong>A:</strong> Use the dropdown to select a score from 1 to 5.</li>
          <li><strong>Q:</strong> What should I include in the feedback?<br /><strong>A:</strong> Provide constructive feedback focusing on the paper's strengths and weaknesses.</li>
          <li><strong>Q:</strong> Can I change my review later?<br /><strong>A:</strong> Once a review is submitted, it cannot be changed.</li>
          <li><strong>Q:</strong> What is the deadline for submitting reviews?<br /><strong>A:</strong> You will receive an email with your review deadline.</li>
        </ul>
      </div>
    </div>
  );
};

export default PeerReview;
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link for navigation

const Login = ({ setIsLoggedIn }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Hardcode admin email and password for this example
    const adminEmail = "admin@example.com";
    const adminPassword = "admin123";

    // Check if the provided email and password match the hardcoded credentials
    if (formData.email === adminEmail && formData.password === adminPassword) {
      localStorage.setItem('isLoggedIn', 'true'); 
      setIsLoggedIn(true);
      navigate('/admin-dashboard'); // Redirect to admin dashboard
    } else {
      // If credentials don't match, show an error
      setError('Invalid email or password');
    }
  };

  return (
    <div className="login-page">
      <form onSubmit={handleSubmit}>
        <h2>Login</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <label>Email</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <label>Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <button type="submit">Login</button>
      </form>

      {/* Registration Link placed inside the login box */}
      <p style={{ textAlign: 'center', marginTop: '10px' }}>
        Not registered? <Link to="/register">Register here</Link>
      </p>


    </div>
  );
};

export default Login;
